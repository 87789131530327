import React from 'react';
import Layout from '../components/common/Layout';
import SEO from '../components/common/SEO';
import website from '../../config/website';

const NotFoundPage = ({ location }) => (
  <Layout customSEO>
    <SEO title={`404 | ${website.siteTitle}`} pathname={location.pathname} />
    <section className="hero is-primary is-bold is-large">
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section is-centered">
                <h1 className="title">404: Nije pronadjeno</h1>
                <h2 className="subtitle">
                  Adresa strane koju tražite ne postoji...
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
